import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { BsMap, BsEnvelope, BsClock } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Turina Driving School
			</title>
			<meta name={"description"} content={"Your Road to Excellence Starts Here"} />
			<meta property={"og:title"} content={"Turina Driving School"} />
			<meta property={"og:description"} content={"Your Road to Excellence Starts Here"} />
			<meta property={"og:image"} content={"https://turinainspace.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://turinainspace.com/img/unnamed.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://turinainspace.com/img/unnamed.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://turinainspace.com/img/unnamed.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://turinainspace.com/img/unnamed.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://turinainspace.com/img/unnamed.png"} />
			<meta name={"msapplication-TileImage"} content={"https://turinainspace.com/img/unnamed.png"} />
			<meta name={"msapplication-TileColor"} content={"https://turinainspace.com/img/unnamed.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Contacts-7">
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Contact Us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Ready to embark on your journey towards driving excellence? Contact us to discuss your goals, schedule your lessons, and take the first step towards becoming a skilled and confident driver.{" "}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 0 0px"
				sm-grid-template-columns="1fr"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Address
					</Text>
					<Text
						margin="0px 0px 0 0px"
						color="--primary"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						FF 20, MADHAV PARK COMPLEX, Sun Pharma Rd, Tandalja, Vadodara, Gujarat 390012, India
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Send message
					</Text>
					<Link
						href="tel:+91 73422 10054"
						color="--primary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						text-align="center"
						margin="0px 0px 8px 0px"
					>
						+91 73422 10054
					</Link>
					<Link
						href="mailto:info@turinainspace.com"
						color="--primary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 0 0px"
						text-align="center"
					>
						info@turinainspace.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsClock}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Opening hours
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="--primary"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
					>
						Mon-Fri 8:00 – 18:00
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});